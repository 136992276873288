import React, { Component } from 'react';
import { Button , FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';
import { UPLOAD_IMAGE, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_ERROR } from '../constants/ActionTypes';
import MS from '../lib/graphics';

const mapDispatchToProps = dispatch => ({
    onSubmit: file =>
    dispatch({ type: UPLOAD_IMAGE,
      file: file }),
    onSuccess: data => dispatch({
      type: UPLOAD_IMAGE_SUCCESS,
      response: data
    }),
    onError: error => dispatch({
      type: UPLOAD_IMAGE_ERROR,
      error: error
    })
});

class Input extends React.Component {

	constructor(props) {
		super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);

	}

   handleClick() {
      console.log("Called click event")
      this.fileInput.click();
  }



handleFileUpload = e => {
   const imageFile = e.target.files[0];
   this.props.onSubmit(imageFile);
   MS.Graphics.resizeFromFile(imageFile,1000).then(response => {

      let imageData ;
      imageData= response.replace('data:image/jpeg;base64,', '');
      imageData = imageData.replace('data:image/png;base64,','');
      this.uploadStart = performance.now();
      fetch('https://prod.apig.ccnag.com/dynamiclpi/validate', {
        method: 'POST',
        headers: {
          'x-api-key': 'grzFO1LZb92Yv6Pzj98tc9Ncpwq5C8tA8sWcNA0L'
        },
        body: JSON.stringify({
                  'imageData': imageData
              })
      })
      .then(response => {
        if (!response.ok) {
           throw Error(response.statusText);
       }
        return response.json();

      })
	   .then(data => {
       console.log("data");
       console.log(data);
       var jsonObj = JSON.parse(JSON.stringify(data));
		   console.log(jsonObj);
       this.props.onSuccess(jsonObj);
     })
     .catch(error => {
       console.log(error);
       this.props.onError(error);
     });
   });


}



	render() {
		var containerStyle = {
			fontFamily: '"Lato", sans-serif',
			padding: '0 0 30px 0',
			overflow: 'hidden'
		};



		return (
			<div className="container-fluid text-center">
	 		 <div className="row">
	 			 <div style={containerStyle}>

	 				 <div className="col-sm-12">

           <input

            type="file"
            ref={(input) => { this.fileInput = input; }}
            accept="image/*"
            capture="camera"
            className="hidden"
            onChange={this.handleFileUpload}
            />
           <Button bsStyle="primary" bsSize="large" onClick={this.handleClick}>
            <span className="glyphicon glyphicon-camera"></span> Camera
            </Button>

	 				</div>
	 			</div>
	 		</div>
	  </div>

    );
  }
}
export default connect(() => ({}), mapDispatchToProps)(Input);
