import React from 'react';

import ResultComponent from './ResultComponent'





const Results = ({ response , error , status}) => {
    console.log("response is  "+response);
    let statusDiv;
    let successDiv;
    let failureDiv;
    let addCss='';
    if (status && status === 'Processing') {

      statusDiv = (
      <div className="container-fluid text-center">
        <div className="row">
          <div className="col-sm-12 loader loader-default is-active">
             {status}
          </div>
         </div>
        </div>

    );
  } else  {
    statusDiv = (
    <div className="container-fluid text-center">
      <div className="row">
        <div className="col-sm-12">
           {status}
        </div>
       </div>
      </div>
    );
  }

    successDiv = (

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
           <ResultComponent response={response}/>
          </div>
         </div>
        </div>

    );

    failureDiv = (

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
              {error}
          </div>
         </div>
        </div>

    );

    return (
      <div>
      {statusDiv}
      {failureDiv}
      {successDiv}
      </div>
    );



}

export default Results;
