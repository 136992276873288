import EXIF from './exif';

let MS 

MS = MS || {};

MS.Graphics = (function(EXIF) {

        var IMAGE_QUALITY = 0.7,
            ORIENTATION_0_DEGREES = 1,
            ORIENTATION_90_DEGREES = 8,
            ORIENTATION_180_DEGREES = 3,
            ORIENTATION_270_DEGREES = 6,
            ROTATION_90_DEGREES = -90*Math.PI/180,
            ROTATION_180_DEGREES = 180*Math.PI/180,
            ROTATION_270_DEGREES = 90*Math.PI/180;

        function getNewDimensions(image, size) {
            var dimensions = {};

            if (image.width > image.height) {
                dimensions.height = size;
                dimensions.width = size * image.width / image.height;
            } else {
                dimensions.width = size;
                dimensions.height = size * image.height / image.width;
            }

            return dimensions;
        }

        function setCanvasSizeBasedOnOrientation(canvas, orientation, newDimensions) {
            if (!orientation || orientation === ORIENTATION_0_DEGREES || orientation === ORIENTATION_180_DEGREES) {
                // default, 3 or 1 for 0 or 180 degrees rotation
                canvas.width = newDimensions.width;
                canvas.height = newDimensions.height;
            } else {
                // 8 or 6 for 90 or 270 degrees rotation
                canvas.width = newDimensions.height;
                canvas.height = newDimensions.width;
            }
        }


        function rotateImageInCanvasContext(ctx, orientation, newDimensions) {
            switch(orientation){
                case ORIENTATION_90_DEGREES:
                    ctx.rotate(ROTATION_90_DEGREES);
                    ctx.translate(-newDimensions.width, 0);
                    break;
                case ORIENTATION_180_DEGREES:
                    ctx.rotate(ROTATION_180_DEGREES);
                    ctx.translate(-newDimensions.width, -newDimensions.height);
                    break;
                case ORIENTATION_270_DEGREES:
                    ctx.rotate(ROTATION_270_DEGREES);
                    ctx.translate(0, -newDimensions.height);
                    break;
            }
        }

        function resizeFromBase64(imageBase64, size, orientation) {
            return new Promise(function(resolve, reject) {
                var image = new Image();

                image.onload = function() {
                    var newDimensions = getNewDimensions(image, size),
                        canvas = document.createElement('canvas'),
                        ctx = canvas.getContext("2d");

                    setCanvasSizeBasedOnOrientation(canvas, orientation, newDimensions);
                    rotateImageInCanvasContext(ctx, orientation, newDimensions);

                    ctx.drawImage(image, 0, 0, newDimensions.width, newDimensions.height);

                    resolve(canvas.toDataURL('image/jpeg', IMAGE_QUALITY));
                };

                image.onerror = function() {
                    reject('An error has occurred while reading the image file.');
                };

                image.src = imageBase64;
            });
        }

        /* Do a proportional image resizing */
        function resizeFromFile(file, size) {
            return new Promise(function(resolve, reject) {
                var reader,
                    sizeIsValid = size && Number.isInteger(size) && size > 0;

                if (!sizeIsValid) {
                    return reject('Parameter "size" must be a positive integer');
                }

                reader = new FileReader();

                EXIF.getData(file, function() {
                    reader.onload = function() {
                        var imageBase64 = reader.result,
                            // FILE should only have the exif properties explicity after calling EXIF.getData from it
                            orientation = EXIF.getTag(file, 'Orientation');
                            console.log('orientation: ' + orientation);

                        resizeFromBase64(imageBase64, size, orientation).then(function(response) {
                            resolve(response);
                        }, function(error) {
                            reject(error);
                        });
                    };
                });

                reader.readAsDataURL(file);
            });
        }

        function getBase64(file) {
         return new Promise(function(resolve, reject){
           var reader = new FileReader();
           reader.readAsDataURL(file);
           reader.onload = function () {

             resolve(reader.result);
           };
         reader.onerror = function (error) {
           console.log('Error: ', error);
           reject(error);

         }
       });

        }

        return {
            resizeFromFile: resizeFromFile,
            resizeFromBase64: resizeFromBase64,
            getBase64:getBase64
        };
}(EXIF));

export default MS;
