import React, { Component } from 'react';


class ResultComponent extends React.Component {

	constructor(props) {
		super(props)
	}

	render() {
    let dynamicIcon;
    let staticIcon;
    let liquidDetails;
		let noIcon;
    var contentStyle = {
		 fontSize: '20px',
		 margin: 0
	 };
	 if(this.props.response && this.props.response.dynamicIcon && this.props.response.dynamicIcon.Error &&
	  this.props.response.staticIcon && this.props.response.staticIcon.Error ) {
		 noIcon =  ( <ul className='list-group'>
						<li className="list-group-item">No Icon Found in the Image</li>
						</ul>);
	 }

      if(this.props.response && this.props.response.dynamicIcon) {
      dynamicIcon = (
            <ul className='list-group'>
              { Object.keys(this.props.response.dynamicIcon).map((k, index) => <li className="list-group-item" key={index}>{ `${k}: ${this.props.response.dynamicIcon[k]}` }</li>) }
            </ul>
      );
    }
      if(this.props.response && this.props.response.staticIcon) {
      staticIcon = (
            <ul className='list-group'>
              { Object.keys(this.props.response.staticIcon).map((k, index) => <li className="list-group-item" key={index}>{ `${k}: ${this.props.response.staticIcon[k]}` }</li>) }
            </ul>
      );
    }
      if(this.props.response && this.props.response.liquidDetails) {
      liquidDetails = (
        <div>
        <p style={contentStyle}>
           Details of the Icon
        </p>
            <ul className='list-group'>
              { Object.keys(this.props.response.liquidDetails).map((k, index) => <li className="list-group-item" key={index}>{ `${k}: ${this.props.response.liquidDetails[k]}` }</li>) }
            </ul>
          </div>
      );
    }
      return (
        <div>
				  {noIcon}
          {staticIcon}
          {dynamicIcon}
          {liquidDetails}
        </div>
      );
    }

}

export default ResultComponent;
