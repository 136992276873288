import { connect } from 'react-redux';
import  Results  from '../components/Results';

const mapStateToProps = (state) => ({
  response: state.data,
  error: state.error,
  status: state.status
})


const ResultsContainer = connect(
  mapStateToProps,
  null
)(Results)

export default ResultsContainer
