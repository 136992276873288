import React, { Component } from 'react';
import { Jumbotron, Image, PageHeader } from 'react-bootstrap';
import Header from './components/Header';
import Introduction from './components/Introduction';
import Input from './components/Input';
import Instructions from './components/Instructions';
import ResultsContainer from './containers/ResultsContainer';
import { connect } from 'react-redux';



class App extends Component {
  render() {

    return (

         <div>
        <Header/>
        <Introduction/>
        <Input/>
        <Instructions/>
        <ResultsContainer/>
        </div>





    );

  }
}

export default App;
