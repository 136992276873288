import React, { Component } from 'react';


class Introduction extends React.Component {

	constructor(props) {
		super(props)
	}

	render() {
		var containerStyle = {
			fontFamily: '"Lato", sans-serif',
			padding: '0 0 30px 0',
			overflow: 'hidden'
		};

		var introductionHeaderStyle = {
			fontSize: '26px',
				lineHeight: '36px',
				fontWeight: '300',
				margin: '0 0 30px 0',

		};
		var contentStyle = {
		 fontSize: '20px',
		 margin: 0
	 };
		return (
			<div className="container-fluid text-center">
	 		 <div className="row">
	 			 <div style={containerStyle}>

	 				 <div className="col-sm-12">
	 					 <h3 style={introductionHeaderStyle}>
	 						 Dynamic LiquID Print Validator
	 					 </h3>
	 					 <p style={contentStyle}>
	 						 Use this tool to validate and
	 						decode Dynamic LiquID Print Icons only.
	 					</p>
	 				</div>
	 			</div>
	 		</div>
	  </div>

    );
  }
}
export default Introduction;
