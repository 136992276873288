import React, { Component } from 'react';


class Instructions extends React.Component {

	constructor(props) {
		super(props)
	}

	render() {
		var containerStyle = {
			fontFamily: '"Lato", sans-serif',
			padding: '0 0 30px 0',
			overflow: 'hidden'
		};

		var introductionHeaderStyle = {
			fontSize: '26px',
				lineHeight: '36px',
				fontWeight: '300',
				margin: '0 0 30px 0',

		};
		var contentStyle = {
		 fontSize: '20px',
		 margin: 0
	 };
		return (
			<div className="container-fluid">
	 		 <div className="row">
	 			 <div style={containerStyle}>

	 				 <div className="col-sm-12 borderless">
					 <ul className="list-unstyled">

							<li>1. Click Camera button.</li>
							<li>2. Use Camera to take a Picture with proper focus </li>
							<li>3. Results should appear after processing. Please Retry if it fails to decode.</li>
					</ul>


	 				</div>
	 			</div>
	 		</div>
	  </div>

    );
  }
}
export default Instructions;
