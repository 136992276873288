import React, { Component } from 'react';
import { Image } from 'react-bootstrap';


class Header extends React.Component {

	constructor(props) {
		super(props)
	}

	render() {
		return (
      <div className="container-fluid text-center">
        <div className="row">
          <div className="col-sm-12">
            
            <Image src="/images/SipScan.png" responsive />
          </div>

       </div>
      </div>
    );
  }
}
export default Header;
