import { UPLOAD_IMAGE, UPLOAD_IMAGE_ERROR, UPLOAD_IMAGE_SUCCESS } from '../constants/ActionTypes';
import { combineReducers } from 'redux';

const initialState = [
  {

    status : '',
    data : {},
    error : '',


  }
]

export default function decode(state = initialState, action) {
  console.log(action.type);
  switch (action.type) {
    case UPLOAD_IMAGE: return { ...state, status: 'Processing', file : action.file, data : {}};
    case UPLOAD_IMAGE_SUCCESS : return { ...state, status: 'Successfully Decoded Icon', data : action.response, error :'' } ;
    case UPLOAD_IMAGE_ERROR :  return { ...state, status: 'Failed to Decode Icon', error : action.error, data :{} };
    default:
    return state;
}

return state;
};
